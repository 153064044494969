import React from 'react'
import HeaderBar from "../components/HeaderBar";
import Footer from "../components/Footer";
import '../css/expertise.css';
import headerBackground from '../assets/expertise/expertise__background.webp';

class Expertise extends React.Component {
    constructor(props) {
        document.title = "Deltalex - Expertise & socials"
        window.scroll({top: 0})
        super(props);
    }

    render() {
        return (
            <div className="expertise__root--wrapper">
                <div className='expertise__header--wrapper'>
                    <HeaderBar CurrentPage="expertise" pageTitle={document.title} />
                    <span className='expertise__header--overlay' />
                    <img src={headerBackground} alt="expertise background" className='expertise__header--image' />
                </div>
                <div className='expertise__main--wrapper'>
                    <div className='expertise__wrapper'>
                        <h1 className='expertise__main--title'>Expertise</h1>
                        <p>
                            Advocatenkantoor Deltalex verdedigt op gedreven wijze uw belangen, dit zowel als u een particulier bent, dan wel u een bedrijf bent.
                        </p><p>
                            Hierbij staan juridische diepgang en grondige dossierkennis centraal.
                        </p><p>
                            Wij hebben reeds meerdere ervaring in vele verschillende takken van het recht. In een eerste verkennend gesprek evalueren wij uw dossier en adviseren wij u welke weg er best gevolgd wordt.
                        </p><p>
                            Wij doen dit telkens in volle transparantie en zorgen ervoor dat u steeds de evolutie van uw dossier ter dege kan volgen. Bij ons kan u dan ook terecht voor vele uiteenlopende materies, zoals daar niet limitatief zijn:
                        </p>
                        <div className='expertise__list--wrapper'>
                            <div className='expertise__item--wrapper'>
                                <h2 className='expertise__title'>Ondernemingsrecht</h2>
                                <ul className='expertise__list'>
                                    <li className='expertise__list--item'>Incasso</li>
                                    <li className='expertise__list--item'>Insolventie</li>
                                    <li className='expertise__list--item'>Opmaak/nazicht overeenkomsten</li>
                                    <li className='expertise__list--item'>Audit/M&A</li>
                                    <li className='expertise__list--item'>Vennootschappen</li>
                                </ul>
                            </div>
                            <div className='expertise__item--wrapper'>
                                <h2 className='expertise__title'>Privaat & familierecht</h2>
                                <ul className='expertise__list'>
                                    <li className='expertise__list--item'>Huurgeschil</li>
                                    <li className='expertise__list--item'>Echtscheiding</li>
                                    <li className='expertise__list--item'>Omgangsrecht & onderhoudsbijdrage</li>
                                    <li className='expertise__list--item'>(Mede-)eigendomsrecht</li>
                                    <li className='expertise__list--item'>Burenhinder</li>
                                    <li className='expertise__list--item'>Erf- & afstammingsrecht</li>
                                </ul>
                            </div>
                            <div className='expertise__item--wrapper'>
                                <h2 className='expertise__title'>Aannemingsrecht</h2>
                                <ul className='expertise__list'>
                                    <li className='expertise__list--item'>Bouwgeschillen</li>
                                    <li className='expertise__list--item'>Expertises</li>
                                    <li className='expertise__list--item'>Aanemingsovereenkomsten</li>
                                    <li className='expertise__list--item'>Overheidsopdrachten</li>
                                </ul>
                            </div>
                            <div className='expertise__item--wrapper'>
                                <h2 className='expertise__title'>Strafrecht</h2>
                                <ul className='expertise__list'>
                                    <li className='expertise__list--item'>Verkeersinbreuken</li>
                                    <li className='expertise__list--item'>Burgerlijke aansprakelijkheid</li>
                                    <li className='expertise__list--item'>Burgerlijke partijstelling</li>
                                    <li className='expertise__list--item'>Verdediging correctioneel</li>
                                    <li className='expertise__list--item'>Bijstand verhoor</li>
                                </ul>
                            </div>
                            <div className='expertise__item--wrapper'>
                                <h2 className='expertise__title'>Administratief recht</h2>
                                <ul className='expertise__list'>
                                    <li className='expertise__list--item'>Geschil met administratie</li>
                                    <li className='expertise__list--item'>Bouwovertredingen</li>
                                    <li className='expertise__list--item'>Overheidsreglementering (leegstand, gasboete, ...)</li>
                                </ul>
                            </div>
                            <div className='expertise__item--wrapper'>
                                <h2 className='expertise__title'>Sociaal recht</h2>
                                <ul className='expertise__list'>
                                    <li className='expertise__list--item'>Ontslag</li>
                                    <li className='expertise__list--item'>Collectieve schuldenregeling</li>
                                    <li className='expertise__list--item'>Preventie & veiligheid</li>
                                    <li className='expertise__list--item'>Arbeidsovereenkomsten</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='socials__main--wrapper'>

                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default Expertise;
