import React from 'react';
import HeaderBar from "../components/HeaderBar";
import teamHeaderImage from '../assets/team/team__header.webp';
import '../css/team.css';
import lawyersList from "../assets/team/lawyers";
import mailInactive from "../assets/team/mail__inactive.svg";
import mailActive from "../assets/team/mail__active.svg"
import arrowDown from "../assets/team/arrow.svg";
import Footer from "../components/Footer"

class Team extends React.Component {
    constructor(props) {
        window.scroll({top: 0})
        super(props);
        document.title = "Deltalex - Het team";
        let initialLawyer = lawyersList.find(({ id }) => id === 1);

        this.state = {
            lawyers: lawyersList,
            spotlightedLawyer: initialLawyer,
            mailImageSource: mailInactive
        }

        this.loopLawyerGrid();
        this.reOrderLawyerList(initialLawyer)
    }

    render() {
        return (
            <div className="team__root--wrapper">
                <div className="team__header--wrapper">
                    <HeaderBar CurrentPage="team" pageTitle={document.title} />
                    <img className="header__image" src={teamHeaderImage} alt="team header" />
                </div>
                {this.renderMainContent()}
                <Footer />
            </div>
        );
    }

    renderMainContent() {
        if (window.innerWidth >= 550) {
            return this.renderDesktopContent();
        } else {
            return this.renderMobileContent();
        }
    }

    renderDesktopContent() {
        return (
            <div className="team__main--wrapper">
                <div className="team__spotlight--wrapper">
                    <div className="spotlighted__wrapper">
                        <h1 className="spotlighted__name">{this.state.spotlightedLawyer.name}</h1>
                        <a href={`mailto:${this.state.spotlightedLawyer.email}`}
                            className="spotlighted__mailto--wrapper"
                            onMouseEnter={() => {
                                this.setState({ mailImageSource: mailActive })
                            }}
                            onMouseLeave={() => {
                                this.setState({ mailImageSource: mailInactive })
                            }}>
                            <img className="spotlighted__mailto--icon" alt="mail to" src={this.state.mailImageSource} />
                            <p className="spotlighted__mailto--text">{this.state.spotlightedLawyer.email}</p>
                        </a>
                        {this.state.spotlightedLawyer.Description}
                    </div>
                </div>
                <div className="search__suggestion--wrapper">
                    <p className="search__suggestion--text">Klik op een advocaat om meer info te krijgen</p>
                    <img className="search__suggestion--arrow" alt="search suggestion arrow" src={arrowDown} />
                </div>
                <div className="team__grid--wrapper">
                    {this.loopLawyerGrid()}
                </div>
            </div>
        )
    }

    loopLawyerGrid() {
        const childElements = [];
        let childList = [];

        this.state.lawyers.map((lawyer, key) => {
            const moduloParameter = (key + 1) % 3;

            childList.push(this.generateLawyerElement(lawyer));

            if (moduloParameter === 0) {
                childElements.push(React.createElement("div", { className: "lawyer__grid--section", key: key }, childList));
                childList = [];
            }
            return "";
        });

        return React.createElement("div", { className: "lawyer__grid" }, childElements);
    }

    generateLawyerElement(lawyer) {
        return React.createElement(
            "div",
            {
                className: `lawyer__wrapper ${lawyer.id === this.state.spotlightedLawyer.id ? " lawyerSelected" : ""}`,
                onClick: () => { this.handleLawyerClick(lawyer) },
                key: this.state.lawyers.indexOf(lawyer)
            }, [<div className='lawyer__overlay' key={this.state.lawyers.indexOf(lawyer)} />, lawyer.image]
        );
    }

    handleLawyerClick(lawyer) {
        const autoScrollParameter = (document.querySelector(".team__header--wrapper").getBoundingClientRect().bottom) + window.scrollY - 150

        this.setState({ spotlightedLawyer: lawyer });
        window.scroll({ left: 0, top: autoScrollParameter });
        this.reOrderLawyerList(lawyer);
    }

    reOrderLawyerList(lawyer) {
        this.state.lawyers.splice(this.state.lawyers.indexOf(lawyer), 1);
        this.state.lawyers.unshift(lawyer);
    }

    renderMobileContent() {
        return (
            <div className='lawyer__list--wrapper'>
                {this.state.lawyers.map((lawyer, key) => {
                    return (
                        <div key={key} className='lawyer__item--wrapper'>
                            <div className='lawyer__head--wrapper'>
                                {lawyer.image}
                                <div className='lawyer__head--text'>
                                    <h1 className='lawyer__text--name'>{lawyer.name}</h1>
                                    <a className='lawyer__mailto--wrapper' href={`mailto:${lawyer.email}`}>
                                        <img src={mailInactive} alt="mailto icon" className='lawyer__mailto--icon' />
                                        <p className='lawyer__mailto--text'>{lawyer.email}</p>
                                    </a>
                                </div>
                            </div>
                            <div className='lawyer__body--wrapper'>
                                <div className='lawyer__text--description'>{lawyer.Description}</div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }
}

export default Team;
